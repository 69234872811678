<template>
  <loginForm type="messageLogin" />
</template>

<script>
import loginForm from '@/components/loginForm'

export default {
  name: 'MessageLogin',
  components: {
    loginForm
  }
}
</script>
